import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const SoftwareDevServicesProps = {
  bannerProps: {
    bannerHeading: (
      <>
        End-to-end software development services to help you build{' '}
        <span className="heading-red">scalable and evolutionary solutions</span>
      </>
    ),
    bannerContent:
      'We help assemble and manage a team of world-class software developers to bring your vision to life. Our software development team helps you transform your business with the latest tools, resources, and most importantly, a winning culture.',
    BannerAlt: 'Software Development Services',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Why Simform For{' '}
        <span className="highlight-head">Software Development</span>{' '}
        <span className="highlight-head">Services?</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            We are a software development company with proven experience in
            providing services that range from customized development to
            complete control on maintenance and support. Stay ahead of your
            competition by tapping into our technical proficiency in software
            development techniques. <br /> <br />
            Our experts help you build cloud-native applications that are high
            in software performance, flexibility, and extensibility. Whether you
            want to migrate to a microservices architecture, modularize existing
            monolithic patterns or exploit cloud environments to the maximum
            potential, our pool of software architects has got you covered.{' '}
            <br />
            <br />
            Upgrade, revamp, migrate or extend your software as you envision
            without deviating from necessary incremental development. Our
            software developers not only condense architecture design and write
            advanced codes, but they also have hands-on experience to track
            progress, measure results, and optimize processes. As a result, you
            get the best of both worlds- operations and development.
          </>
        ),
      },
      {
        detailsList: [
          'Seasoned software developers',
          'Cross-functional agile teams',
          'AWS certified software architectects',
          'Cloud architecture best practices',
          'Transparent and delivery-oriented culture',
          'White-label software development expertise',
          'Proficiency in SaaS and software product development',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our Software Development{' '}
        <span className="highlight-head"> Services</span>
      </>
    ),
    servicesPara:
      'With Simform, you’re one step closer to building your dream software product. Opt from the range of development services available and get your business the much-needed strategic edge.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'Custom Software Development Services',
        className: 'custom-software',
        mainPara:
          'With more than a decade of custom software development experience under our belt, we understand your requirements better than any other company. Leverage our 50+ industry-wide experience to build the customized solution your business needs.',
        listItem: [
          {
            title: 'Legacy Modernization & Migration',
            para:
              'Be it legacy, monolithic, microservices, or nanoservices; we help you re-architect and update your application architecture as per your business requirement.',
          },
          {
            title: 'Data Visualization',
            para:
              'Leverage our BI expertise to track business goals and measure performance. We use custom tools, libraries, and codes to bring out the deeper insights and critical data via intuitive visuals.',
          },
          {
            title: 'Custom Application Development',
            para:
              'Be it simple or complex application, get access to our experience in application consulting, development, and integration services, from configuration to synchronization.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: 'services/custom-software-development/',
      },
      {
        heading: 'Web App Development',
        className: 'software-testing',
        mainPara:
          'Build a robust, scalable, and fully functional application with the right team and technology stack that aligns with your business goals. Be it Single Page Applications, Progressive Web Apps, or any complex web app, our full-stack developers provide world-class frontend and backend services.',
        listItem: [
          {
            title: 'Microservices Architecture Development',
            para:
              'Create room to provide a state of independence to all features within your product. We provide microservices architecture consulting, migration, testing, support, and maintenance services.',
          },
          {
            title: 'Infrastructure Health Audit',
            para:
              'Our dedicated development team audits and reduces the downtime during development or modernization via containerization and orchestration tools to transform your web app cloud-ready.',
          },
          {
            title: 'Progressive Web Application Development',
            para:
              'We build PWAs that are easily installable, network independent, secure and user-engaging. Our PWA experts create rich experiences with various Sync APIs, target APIs, and more.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: 'services/web-apps-development/',
      },
      {
        heading: 'Application Development',
        className: 'enterprise-mobile',
        mainPara:
          'Our tech-savvy specialists are experts in merging application design with development services that cater to B2B or B2C as per your need. Create mobile, web, and desktop apps that withstand the test of time with the opportunity to tap into our AR, VR, and IoT expertise.',
        listItem: [
          {
            title: 'eCommerce Development',
            para:
              'We develop feature-rich and expandable eCommerce web and mobile apps that are ready to use. We highly customize apps with multiple service packages, system integrations, and support.',
          },
          {
            title: 'SaaS Application Development',
            para:
              'Build and re-architect applications for SaaS environments that run on remote cloud networks. Achieve flexibility and application scalability without having to worry about the server capacity.',
          },
          {
            title: 'Enterprise Application Development',
            para:
              'Create user-centric enterprise apps that are innovative with smart integration capabilities and mobility solutions irrespective of the platform. We offer native, hybrid, and cross-platform development.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: 'services/application-development/',
      },
      {
        heading: 'Mobile App Development',
        className: 'web-application',
        mainPara: (
          <>
            Create innovative applications for both iOS and Android platforms
            independent or codependent on each other by creating cross-platform
            and hybrid apps. Our software developers are experts in using
            different Software Development Kits (SDKs) and development
            toolchains.
          </>
        ),
        listItem: [
          {
            title: 'Mobile App Consultation',
            para:
              'Integrate unique APIs that extend the use case of your application. We offer the perfect roadmap, strategy plans, process automation to deliver a flexible and lightweight mobile app.',
          },
          {
            title: 'Native App Development',
            para:
              'Get access to the best runtime performance by building platform-specific apps with platform-centric frameworks. Create features that have access to device hardware and obtain better native support. ',
          },
          {
            title: 'Hybrid & Cross-platform Development',
            para:
              'Reduce development time by writing a single codebase for multiple platforms. We build apps that have easily shareable codes between mobile, web, and even desktop apps.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: 'services/mobile-app-development/',
      },
      {
        heading: 'API Integration Services',
        className: 'dedicated-software',
        mainPara:
          'Integrate two or more external systems with your app to achieve greater results. Improve user engagement by leveraging internal APIs to function parallel with third-party APIs, tools, and libraries. Build dedicated APIs to increase the interoperability of your application along with cloud support. ',
        listItem: [
          {
            title: 'API Security Testing',
            para:
              'Create automated tests that verify and ensure the performance of the API and the application architecture under simulated conditions. Get access to end reports to monitor and track all API layers for possible optimizations.',
          },
          {
            title: 'Integration of Cloud APIs',
            para:
              'Our API development teams create dedicated APIs for the cloud, web, and mobile services. Connect various data sources and cloud APIs that deal with storage, performance, database management without compromising security.',
          },
          {
            title: 'API as a Service Development',
            para:
              'Build apps with APIaaS capabilities to leverage the essence of SaaS, where users can interact with third-party APIs and also manage custom APIs by themselves.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: 'services/api-integration-development/',
      },
      {
        heading: 'White Label Software Development',
        className: 'product-development',
        mainPara:
          'Take care of your complex website CMS integrations, markup projects, resale of your web or mobile design applications or services by white labeling them. Take advantage of our white label software development services to create apps that are ready for the market from the shelf instead of building from scratch. ',
        listItem: [
          {
            title: 'MVP Development',
            para: (
              <>
                Avoid cost incurred in reinventing the wheel of a problem by
                white labeling an application to create MVP aligned with your
                business goals. Our experts revamp the app's UI partially or
                entirely to improve user engagement.
              </>
            ),
          },
          {
            title: 'White Label Web Solutions',
            para:
              'Get access to both front-end and backend white labeling services. Rebrand the desired external or existing services and present them to the user under your band name and in the way you envision it. ',
          },
          {
            title: 'White Label Consultation',
            para:
              'Expand the web app offerings by adding white label services to the software to build brand credibility, reduce development costs, and comprehensively scale existing services.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/white-label-software-development/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AI/ML Development',
        className: 'api-integration',
        mainPara:
          'Go the extra mile to automate operations, transform legacy features to function intelligently with data and analytics via Artificial Intelligence (AI) and Machine Learning (ML). Convert your AI/ML aspirations and ideas into tangible business results by tapping into our Deep Learning and NLP expertise.',
        listItem: [
          {
            title: 'Chatbot Development',
            para:
              'Build AI-powered and ML-coded chatbots that are interactive and customized with self-learning capabilities and context sensitivity. We integrate chatbots into ERP systems, mobile and web apps with multilingual support.',
          },
          {
            title: 'Data Science Services',
            para:
              'Accelerate the time taken for services to be processed in your application with data science tools. Track, measure, and govern the app to predict functionality outcomes by converting data into actionable information. ',
          },
          {
            title: 'AI/ML Product Development',
            para:
              'Process high volumes of data, content like images, videos, texts, and many more through AI and ML-powered tools and frameworks. Establish correlation between functions for optimal resource usage.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: 'services/artificial-intelligence-development/',
      },
      {
        heading: 'Software Product Development',
        className: 'application-development',
        mainPara:
          'Create new software from scratch or scale the existing application with new solutions and value-added features. Our team follows a holistic approach that bridges the gap between the digital experience and the user’s satisfaction from requirement gathering, development, and testing to deployment. ',
        listItem: [
          {
            title: 'Micro Front-end Development',
            para:
              ' Build resilient and robust applications with feature-rich front-end layers. Distinct areas of your applications will be taken responsibility by an independent team of experts based on the composition of your application architecture.',
          },
          {
            title: 'Component-Based Architecture Creation',
            para:
              'Decompose the tightly-coupled architecture into individual functions or separate components based on logic to improve communication with high component reusability.',
          },
          {
            title: 'Software Support and Maintenance',
            para:
              'Upgrade, update, or evolve your software at any time based on your requirement to improve application efficiency. Furthermore, we integrate, migrate, test, secure, and customize your software services at all QA levels.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: 'services/software-product-development/',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    processPara:
      'There is no one-size, one-idea-fits-all kind of solution. At Simform, our software engineers are versatile and constantly improve on their skillset who can adapt and evolve according to the requirement of your application development.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Eminent expertise',
        workingBenefitsContent: (
          <>
            Our expert development team dedicates 100% of their time to the
            assigned client. They also nurture long-term relationships and gain
            in-depth knowledge of their business domain.
          </>
        ),
        benefitImgAlt: 'Eminent expertise',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Intrinsically agile',
        workingBenefitsContent: (
          <>
            Our strong pillar is that we follow adaptive planning in an agile
            approach to build apps flexibly. This helps us reduce repetition in
            work and empower cross-functional team collaboration to deliver
            projects on time.
          </>
        ),
        benefitImgAlt: 'Intrinsically agile',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'End-to-end services',
        workingBenefitsContent: (
          <>
            We have a proven record of satisfied clients. Be its cloud
            solutions, development or mobile, web or desktop apps, creation of
            custom, enterprise, or SaaS apps. We have a team ready for your
            every need.
          </>
        ),
        benefitImgAlt: 'End-to-end services',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Scalable team',
        workingBenefitsContent: (
          <>
            Build a distributed team filled with the right talent that can meet
            different market demands. Incorporate additional developers who can
            expand your existing team and add value to your project.
          </>
        ),
        benefitImgAlt: 'Scalable team',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Unmatched quality',
        workingBenefitsContent: (
          <>
            Beyond development, we take it seriously to make sure the deployed
            product is compliant with set standards for security, performance,
            dependencies, and so much more. We make room for constant
            improvement.
          </>
        ),
        benefitImgAlt: 'Unmatched quality',
      },
    ],
  },
  customDevProps: {
    serviceHeading: (
      <>
        Software Development in <br />
        <span className="highlight-head">Cutting-Edge Technologies</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Big Data',
        serviceParagraph: (
          <>
            Handle enormous amounts of data and limitless tasks virtually using
            cloud service-compatible frameworks like MongoDB, Hadoop, etc.
          </>
        ),
        serviceIcon: images.scloudApp,
        serviceAlt: 'Mobile',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Internet of Things (IoT)',
        serviceParagraph: (
          <>
            Scale your application smartly. Ranging from middleware to
            architecture creation, our team builds sleek IoT interfaces for all
            MVP.
          </>
        ),
        serviceIcon: images.sInternetofThings,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Artificial Intelligence (AI)',
        serviceParagraph: (
          <>
            Harness of the power of automation. We create AI capabilities that
            are reliable and interpretable with your software architecture.
          </>
        ),
        serviceIcon: images.sartificialIntelligence,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'VR and AR app Development',
        serviceParagraph: (
          <>
            Leverage the power of VR and AR. Tap into our WebVR, photogrammetry,
            and Storyboarding skills in development.
          </>
        ),
        serviceIcon: images.svrArAppDev,
        serviceAlt: 'Software',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: 'Machine Learning (ML)',
        serviceParagraph: (
          <>
            Develop your app intelligently. Build, train, and deploy ML models
            for various use cases of your application as desired.
          </>
        ),
        serviceIcon: images.smachineLearning,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Blockchain',
        serviceParagraph: (
          <>
            Create DApps, build multi-blockchain compatible features, smart
            contracts with cryptocurrency solutions, and integrate REST APIs.
          </>
        ),
        serviceIcon: images.sblockchain,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Custom Software</span>
        <br />
        Development Case Studies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">Customers Say</span>
        <br />
        About Our Services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  workFlowProps: {
    heading: (
      <>
        Software Development <br />
        <span className="highlight-head">Process</span>
      </>
    ),
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.iconTeam,
        workFlowIconAlt: 'Choosing the team',
        workFlowTitle: 'Choosing the team',
        workFlowParagraph:
          'We pick a talent pool with the right team, the right expertise, domain knowledge, and willingness to adapt and evolve to your project environment.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.iconSprint,
        workFlowIconAlt: 'Development methodology planning',
        workFlowTitle: 'Development methodology planning',
        workFlowParagraph:
          'Choose the suitable methodology that empowers the development process to be conducted at its best. Our team members follow sprint planning and agile techniques.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.iconTech,
        workFlowIconAlt: 'Architecture creation',
        workFlowTitle: 'Architecture creation',
        workFlowParagraph:
          'We handle all architecture evolution requirements ranging from UI/UX enhancement, decoupling tightly packed features by migrating to microservices or modularizing the existing architecture.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
    ],
    workFlowSecondRows: [
      {
        workFlowClass: 'light-green',
        workFlowCount: '4',
        workFlowIcon: images.iconStandUps,
        workFlowIconAlt: 'Project Delivery',
        workFlowTitle: 'Project Delivery',
        workFlowParagraph:
          'Beyond development, we set various endpoints where at each point, we deploy the completed project in several phases to reduce reiteration and ensure smooth deployment promptly as promised.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '5',
        workFlowIcon: images.iconCodeReviews,
        workFlowIconAlt: 'Intelligent Testing',
        workFlowTitle: 'Intelligent Testing',
        workFlowParagraph:
          'We create useful test cases catered to identify septic storage problems, memory leaks, and security vulnerabilities. We used a variety of testing technologies and tools that best fit your project.',
        workFlowArrowImg: images.iconFlowArrow5,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '6',
        workFlowIcon: images.iconIterate,
        workFlowIconAlt: 'Periodical reviews',
        workFlowTitle: 'Periodical reviews',
        workFlowParagraph:
          'We conduct timely reviews to squash any foreseeable concern,  present weekly demos, and standups to address any existing bottlenecks to ensure everyone is on the same page.',
        workFlowArrowImg: images.iconFlowArrow6,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        Industries <span className="highlight-head">We Serve</span>
      </>
    ),
    tabContents: [
      {
        tabName: 'Travel',
        contentHeading: 'Rent-a-Car Mobile App',
        contentPara:
          'Create a multilingual application that supports reviews, ratings, photos, 24/7 support with multi-payment gateway features to make the process of travel app services smooth and secured.',
        contentSingleProps: [
          {
            singleblkImg: images.mobilebus1,
            singleblkImg2x: images.mobilebus12x,
          },
          {
            singleblkImg: images.mobilebus2,
            singleblkImg2x: images.mobilebus22x,
          },
          {
            singleblkImg: images.mobilebus3,
            singleblkImg2x: images.mobilebus32x,
          },
        ],
      },
      {
        tabName: 'Finance',
        contentHeading: 'Mobile app for budgeting',
        contentPara:
          'Provide users with capabilities to calculate balances, manage personal investments, features to transfer money, and plan weekly/month budgets. We integrate advanced security APIs to ensure there is no breach.',
        contentSingleProps: [
          {
            singleblkImg: images.mobbanking1,
            singleblkImg2x: images.mobbanking12x,
          },
          {
            singleblkImg: images.mobbanking2,
            singleblkImg2x: images.mobbanking22x,
          },
          {
            singleblkImg: images.mobbanking3,
            singleblkImg2x: images.mobbanking32x,
          },
        ],
      },
      {
        tabName: 'Media & Entertainment',
        contentHeading: 'On-demand streaming Mobile/Web App',
        contentPara:
          'Attract users to your steaming platform with advanced analytics powered by AI, ML, and cloud power to create live-stream and online streaming from servers along with features of a recommendation engine, sharing, and tracking of user behavior.',
        contentSingleProps: [
          {
            singleblkImg: images.mediastream1,
            singleblkImg2x: images.mediastream12x,
          },
          {
            singleblkImg: images.mediastream2,
            singleblkImg2x: images.mediastream22x,
          },
          {
            singleblkImg: images.mediastream3,
            singleblkImg2x: images.mediastream32x,
          },
        ],
      },
      {
        tabName: 'Software',
        contentHeading: 'Warehouse Management Application',
        contentPara:
          'Build a custom SaaS application to manage inventory, stock-in, stock-out, etc., and serve as a central point for managing all B2B operations of the business. We can also create such Paas or APIaaS.',
        contentSingleProps: [
          {
            singleblkImg: images.b2b1,
            singleblkImg2x: images.b2b12x,
          },
          {
            singleblkImg: images.b2b2,
            singleblkImg2x: images.b2b22x,
          },
          {
            singleblkImg: images.b2b3,
            singleblkImg2x: images.b2b32x,
          },
        ],
      },
      {
        tabName: 'Healthcare',
        contentHeading: 'eReporting Mobile App',
        contentPara:
          'Leverage the power of the cloud to build a data management application where all patient reports are stored online for easier and quicker access. We create features for data storing, indexing, cache management, and dependency handling.',
        contentSingleProps: [
          {
            singleblkImg: images.doctor1,
            singleblkImg2x: images.doctor12x,
          },
          {
            singleblkImg: images.doctor2,
            singleblkImg2x: images.doctor22x,
          },
          {
            singleblkImg: images.doctor3,
            singleblkImg2x: images.doctor32x,
          },
        ],
      },
      {
        tabName: 'NGO',
        contentHeading: 'Public Welfare App',
        contentPara:
          'Development of a mobile application that stores necessary demographic detail of the user, which can be correlated against healthcare and food industry to serve the underprivileged in real-time. Predictive analysis through data science programs written for ML model is used in large here.',
        contentSingleProps: [
          {
            singleblkImg: images.publich1,
            singleblkImg2x: images.publich12x,
          },
          {
            singleblkImg: images.publich2,
            singleblkImg2x: images.publich22x,
          },
          {
            singleblkImg: images.publich3,
            singleblkImg2x: images.publich32x,
          },
        ],
      },
      {
        tabName: 'Education',
        contentHeading: 'Virtual Classroom App',
        contentPara:
          'Build a mobile and web app where CMS and CDN are leveraged to deliver content at its best to the students virtually. The app environment is also created with high data management tools to ensure easy access and designed in a way to maintain smooth navigation.',
        contentSingleProps: [
          {
            singleblkImg: images.educationElearning1,
            singleblkImg2x: images.educationElearning12x,
          },
          {
            singleblkImg: images.educationElearning2,
            singleblkImg2x: images.educationElearning22x,
          },
          {
            singleblkImg: images.educationElearning3,
            singleblkImg2x: images.educationElearning32x,
          },
        ],
      },
      {
        tabName: 'Retail breaking down',
        contentHeading: 'Social Marketplace App',
        contentPara:
          'Develop an app where sellers and purchasers can interact without any middleman to buy and rent food trucks and land spaces. Usage of microservices plays a role in breaking down multiple large operations into smaller services decoupling all tasks.',
        contentSingleProps: [
          {
            singleblkImg: images.mobecomm1,
            singleblkImg2x: images.mobecomm12x,
          },
          {
            singleblkImg: images.mobecomm2,
            singleblkImg2x: images.mobecomm22x,
          },
          {
            singleblkImg: images.mobecomm3,
            singleblkImg2x: images.mobecomm32x,
          },
        ],
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle:
          'What kind of software quality control protocols do you follow in development?',
        faqPara: (
          <>
            We strictly adhere to the style guide provided by our client and
            break up the code into small logical units. Post which, we conduct
            varied peer reviews, ad hoc reviews, inspect code for errors through
            metrics like depth of inheritance, Maintainability Index (MI), etc.
          </>
        ),
      },
      {
        faqTitle:
          'What is the benefit of extending my team by hiring your dedicated development team?',
        faqPara: (
          <>
            You get access to remote engineering teams who have diverse talents
            in software development and are capable of increasing your app's
            productivity and interoperability. Save massive amounts of
            investment costs by hiring developers on the go.
          </>
        ),
      },
      {
        faqTitle:
          'What are the cost-incurring factors involved in software development services?',
        faqPara: (
          <>
            The three prominent cost influencers are the type of application
            required, the size of the application, and the hiring model you
            choose. Based on the variant you pick, the cost varies based on the
            involvement level of the team, integration services requested,
            modifications required, testing conducted, post-deployment support,
            and so forth.
          </>
        ),
      },
      {
        faqTitle:
          'How will you comply with Intellectual Property Rights (IPR) for my Software?',
        faqPara: (
          <>
            We have created a service-level agreement and NDA upon which we
            strictly adhere to maintain confidentiality. Upon consensus, once
            the project is initiated, we transfer the full ownership of all IPR
            from developers to our client.
          </>
        ),
      },
      {
        faqTitle: 'How to choose the best software development company?',
        faqPara: (
          <>
            Beyond what you see and hear from your referrals, conduct your
            research on google and various listing platforms to identify the
            company that will best suit your project. Pay attention to details
            like:
            <br />
            <br />
            <ul>
              <li>Design and usability of the company website</li>
              <li>Software development tools and technologies used</li>
              <li>Services provided </li>
              <li>Case studies to understand successful client stories</li>
              <li>Client testimonials </li>
              <li>Social media activity</li>
              <li>Years of experience</li>
              <li>Price quoted for the project </li>
              <li>Development approach followed (Agile, waterfall, etc.)</li>
            </ul>
            <br />
            <br />
            <strong>Tip:</strong> It is better to choose a company that follows
            agile methodologies. Also, apart from the factors mentioned above,
            keenly notice if the communication between the parties is clear and
            smooth, duly note the first impression in how your clarifications
            are resolved and response received.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/cloud-deployment-models-vm-iaas-containers-serverless/',
        featurecardTitle:
          'How to Choose the Right Cloud Deployment Model for your Application?',
      },
      {
        blogLink: 'https://www.simform.com/blog/react-native-app-performance/',
        featurecardTitle:
          'React Native Performance: Major issues and insights on improving your app’s performance',
      },
      {
        blogLink: 'https://www.simform.com/mobile-app-testing/',
        featurecardTitle:
          'Mobile App Testing: Challenges, Types and Best Practices',
      },
    ],
  },
}
